<template>
    <div class="upper-category">

        <div class="section pt20 upper-category__top">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-12">
                        <button @click="goToBack" class="btn btn-back btn-icon btn-round">
                            <span class="material-icons-outlined" aria-hidden="true">{{nextArrow}}</span>
                        </button>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-12 col-xl-4">
                        <div class="category" v-if="categoryFilters!==null">
                            <div class="category-img">
                                <img :src="getMainCategoryIcon(category)" :alt="category">
                            </div>
                            <div class="category-info">
                                <h1 class="category-label">{{categoryFilters.label}}</h1>
                                <h3 class="slogan d-none d-lg-block" v-if="slogan!==null">{{slogan}}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-8" v-if="categoryFilters!==null && categoryFilters.options.length>0">

                        <div class="card-columns">
                            <div class="custom-category-card card" v-for="(category, index) in categoryFilters.options" :key="index">
                                <extended-kategorie-card :mainCategory="mainCategory" :subCategoryId="categoryFilters.field_type_id" :item="category"/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <event-section v-if="mainCategory!==null" :category="mainCategory.value"/>

        <orte-section v-if="mainCategory!==null" :mainCategory="mainCategory"/>

    </div>
</template>

<script>
    import Resource from '@/api/resource';
    const contentTypeResource = new Resource('contenttypes');
    import { getMainCategoryIcon } from '@/utils/lupe-helpers';
    import { showLoader, hideLoader, getStructuredFilters, getBasicFilters } from '@/utils/helpers';

    export default {
        name: 'Category',
        components: {
            Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
            ExtendedKategorieCard: () => import('./components/ExtendedKategorie.vue'),
            OrteSection: () => import('./components/OrteSection.vue'),            
            EventSection: () => import('./components/EventSection.vue'),            
        },
        data() {
            return {
                contentType: null,
            }
        },
        created(){
            this.getOfferSettings();
        },
        computed: {
            isLtr() {
                return !this.$isRtl;
            },
            category() {
                return this.$route.params.category;
            },
            categoryId(){
                if(this.structuredFilters!==null){
                    var kategorieFilter = this.structuredFilters[0];
                    if(kategorieFilter!==undefined){
                        return kategorieFilter.field_type_id;
                    }
                }
                return null;
            },
            structuredFilters(){
             return getStructuredFilters(this.contentType);
         },
         categoryFilters(){
            if(this.structuredFilters!==null){
                var kategorieFilter = this.structuredFilters[0];
                if(kategorieFilter!==undefined){
                    for(var i = 0; i<kategorieFilter.options.length;i++){
                        if(kategorieFilter.options[i].label == this.category){
                            return kategorieFilter.options[i];
                        }
                    }
                }
            }
            return null;
        },
        mainCategory(){
            if(this.categoryFilters!==null){
                return {
                    id: this.categoryId,
                    value : this.categoryFilters.label
                }
            }
            return null;
        },
        basicFilters(){
           return getBasicFilters(this.structuredFilters);
       },
       nextArrow() {
        return this.isLtr ? 'arrow_back' : 'arrow_forward';
    },
    mainBasicFilter(){
        if(this.basicFilters!==null && this.basicFilters.length>0){
            var result = this.basicFilters.splice(0,1);
            return result[0];
        }
        return null;
    },
    slogan(){
        switch(this.category){
            case 'Beratung & Hilfe':
            return 'Finde Unterstützung in allen Lebenslagen.';
            break;
            case 'Freizeit':
            return 'Finde Ideen für deine Freizeitgestaltung.';
            break;
            case 'Ausbildung & Weiterbildung':
            return 'Finde Ausbildungs- & Weiterbildungsangebote.';
            break;
        }
        return null
    }
},
methods: {
 showLoader, 
 hideLoader,
 getMainCategoryIcon,
 getOfferSettings(){
    this.getContentType();
},
goToBack() {
    this.$router.go(-1);
},
getContentType() {
    this.contentType = null;
    var id = this.$offerId;
    this.loader = this.showLoader(this.loader);
    /*overwrite the existing filters*/
    this.selectedFilters = new Map();
    contentTypeResource.get(id).then(response => {
        this.contentType = response.data;
    })
    .finally(() => {
        this.loader = this.hideLoader(this.loader);
    });
},
}
}
</script>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';

    .custom-category-card{
        display: inline-block;
        column-break-inside: avoid;
        border-radius: 3px;
        box-shadow: 0px 2px 4px 0px #00000040;

        @media (max-width: 576px) {
            width: 100%;
        }
    }

    .category-info{
        .slogan{
            @media (max-width: 1520px) {
                font-size: 18px;
            }    
        }
    }

    .category-img {

        @media (max-width: 420px) {
            margin-right: 10px;
            min-width: 90px;
            height: 90px;
        }

        img{
            width: 70px;

            @media (max-width: 420px) {
                width: 60px;
            }
        }
    }

    .card-columns{
        @media (max-width: 880px) {
            column-count: 2;
        }

        @media (max-width: 576px) {
            column-count: 1;
        }
    }

    .category-label{
        color: $primary;
        font-size: 35px;
        font-family: "Myriad", sans-serif;

        @media (max-width: 420px) {
            font-size: 25px;
        }

    }

</style>